.presentations .title-documents {
  display: block;
  font-size: 1.4rem;
  font-weight: bold;
}
.presentations {
  width: 100%;
}
.presentations a.link-document {
  background-color: #6b97cf;
  border: 1px solid #6b97cf;
  color: #ffffff;
  vertical-align: middle;
  text-decoration: none;
  width: 20%;
  padding: 0.8rem;
  font-weight: bold;
  border-radius: 15px;
  font-size: 1.2rem;
  float: right;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.presentations a.link-document:hover {
  border: 1px solid #6b97cf;
  background-color: #ffffff;
  color: #6b97cf;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.presentations .governance-container .row-investment .card-gov,
.presentations .governance-container .row-governance .card-gov {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
@media screen and (max-width: 1150px) {
  .presentations {
    padding: 0 1rem 0 1rem;
    margin-bottom: 4rem !important;
  }
  .presentations .documents-wrapper {
    padding-top: 4rem;
  }
  .presentations .group-detail .border-container {
    height: auto;
    display: flow-root;
  }
  .presentations .group-detail .link-document {
    margin-top: 2rem;
  }
  .presentations .directors .d-flex {
    justify-content: center;
  }
}
