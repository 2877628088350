footer {
  background-color: #305ca7 !important;
  color: #ffffff;
  position: absolute;
  width: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-top: 7rem;
  margin-top: -11rem;
}

footer .row-footer-legal p {
  color: #ffffff !important;
}

footer .container .row-footer-legal.row-info p {
  text-align: left !important;
  color: #ffffff !important;
}

footer .company-logo-footer {
  height: 9em;
}

footer .container .row-footer-legal.row-info svg {
  padding-right: 0.4rem;
}

.row-footer-legal {
  border-top: 1px solid hsla(0, 0%, 100%, 0.25);
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.row-info p,
.row-info a {
  color: #ffffff;
}
.font-social {
  font-size: 1.5rem;
}
.footer-ico-wrapper {
  align-self: flex-end;
}
.row-info .title-text-footer {
  font-weight: bold;
}
.row-info a {
  font-weight: normal;
}
.row-footer-legal p {
  font-size: 0.8rem;
}
.row-footer-legal a.nav-link {
  color: #ffffff;
}
footer a:hover {
  color: #ffffff;
  text-decoration: underline;
}
.pec-footer a {
  text-decoration: underline !important;
}
.footer-container a {
  text-decoration: none;
}
.font-weight-bold {
  font-weight: bold;
}
.pp-cp .text-end {
  display: flex;
  justify-content: end;
}
@media screen and (max-width: 992px) {
  html,
  body {
    width: auto !important;
    overflow-x: hidden !important;
  }
  footer .container .row-info div {
    width: auto;
  }
  footer .container .row-info .col-4 {
    padding-bottom: 1rem !important;
  }
  .rigsave-background-hero {
    height: 35vh;
  }
  footer .container .row-info .col-sm-6.col-md-6 {
    width: 50%;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  footer {
    padding-top: 4rem;
  }
}
