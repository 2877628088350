.border-container {
  background-color: #fff;
  border: 2px solid #6b97cf;
  border-radius: 25px;
  padding: 2rem;
}
.mission,
.vision {
  box-shadow: 0 3rem 0 1rem #305ca7;
}
.group-detail .border-container {
  box-shadow: 5rem 3rem 0 1rem #305ca7, -5rem 3rem 0 1rem #305ca7;
}
.border-container .title {
  font-size: 2rem;
  font-weight: 700;
}
.font-aboutUs {
  font-size: 1.3rem;
}
.mission {
  margin-top: 10rem;
}
.about-us .values-container .title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 3px;
}
.about-us .values-container .font-values {
  font-size: 1rem;
}
.about-us .values-container img {
  height: 5rem;
  margin-bottom: 10px;
}
.about-us a.link-document {
  background-color: #6b97cf;
  border: 1px solid #6b97cf;
  color: #ffffff;
  vertical-align: middle;
  text-decoration: none;
  width: 20%;
  padding: 0.8rem;
  font-weight: bold;
  border-radius: 15px;
  font-size: 1.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.about-us a.link-document:hover {
  border: 1px solid #6b97cf;
  background-color: #ffffff;
  color: #6b97cf;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.governance-container img {
  height: 10rem;
  width: 10rem;
  margin-bottom: 1rem;
}
.governance-container .bold-name {
  font-weight: bold;
}
.governance-container a {
  font-weight: normal;
  text-decoration: underline;
}
.governance-container .card-gov img {
  background-color: #ffffff;
  border-radius: 25px;
  border: 2px solid #6b97cf;
}
.governance-container {
  background-color: #e9e9e9;
  border-radius: 25px;
  margin-top: 9rem;
  position: relative;
  z-index: 1000;
}
.governance-container .title {
  font-size: 2.7rem;
  font-weight: bold;
}
.governance-container .sub-title {
  font-size: 1.8rem;
  font-weight: bold;
}
.governance-container .perc {
  font-size: 1.3rem;
  font-weight: bold;
}
.governance-container .row-investment .card-gov,
.governance-container .row-governance .card-gov {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

@media screen and (max-width: 1150px) {
  .about-us {
    width: 100%;
    padding: 0 1rem 0 1rem;
    margin-bottom: 4rem !important;
  }
  .values-container .row div {
    padding-left: 0.2rem !important;
    padding-right: 0.2rem !important;
  }
  .about-us a.link-document {
    width: 70%;
  }
  .about-us .group-detail {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .group-detail .border-container {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    box-shadow: 0rem 3rem 0 1rem #305ca7, 0rem 3rem 0 1rem #305ca7;
  }
  .group-detail .border-container:first-child {
    margin-bottom: 8rem;
  }
  .governance-container {
    padding: 3rem 0rem !important;
  }
  .governance-container .sub-title {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .governance-container .row-governance {
    text-align: center;
  }
  .governance-container .row-governance div {
    padding-bottom: 1rem !important;
  }
  .directors .d-flex {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .directors .col-lg-6 .d-flex {
    justify-content: center !important;
  }
  .governance-container .board-sub-title,
  .governance-container .board-statutory-title {
    padding-bottom: 2rem !important;
    padding-top: 0 !important;
  }
  .governance-container .button-wrapper {
    padding-top: 0 !important;
  }
}
