.director-dealings #tab-directors-dealings {
  margin-right: 0 !important;
  margin-left: 0 !important;
  border: 0;
}

.wrapper-main:has(.director-dealings) ~ footer {
  padding-top: 1rem !important;
}

.director-dealings #tab-directors-dealings .nav-item button {
  float: right;
  vertical-align: middle;
  padding: 0.8rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out !important;
  background-color: #6b97cf;
  border: 1px solid #6b97cf;
  font-weight: bold;
  border-radius: 15px;
  font-size: 0.9rem;
  color: #ffffff;
  text-decoration: none;
  width: 14rem;
  height: 5rem;
  margin-left: auto;
  margin-right: 0;
}

.director-dealings #tab-directors-dealings .nav-item button:hover {
  border: 1px solid #6b97cf;
  background-color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  color: #6b97cf;
}

.director-dealings #tab-directors-dealings .nav-item button.active {
  border: 1px solid #6b97cf !important;
  background-color: #ffffff !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  color: #6b97cf !important;
}

.director-dealings .tab-content .card-documents {
  border: 1px solid #6b97cf;
  border-radius: 25px;
  padding: 2rem;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 13rem;
  display: block;
}

.director-dealings .tab-content .card-documents .title {
  font-size: 1.7rem;
  font-weight: bold;
}

.director-dealings .tab-content .card-documents .pdf-button {
  float: right;
  width: 50%;
  display: block;
  text-align: center;
  padding: 0.8rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out !important;
  background-color: #6b97cf;
  border: 1px solid #6b97cf;
  font-weight: bold;
  border-radius: 15px;
  font-size: 0.9rem;
  color: #ffffff;
  text-decoration: none;
  width: 12rem;
  margin-left: auto;
  margin-right: 0;
  font-size: 1rem;
}

.director-dealings .tab-content .card-documents .pdf-button:hover {
  border: 1px solid #6b97cf;
  background-color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  color: #6b97cf;
}

.director-dealings #tab-directors-dealings .nav-item button:hover {
  border: 1px solid #6b97cf;
  background-color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  color: #6b97cf;
}

@media screen and (min-width: 1000px) {
  .director-dealings .tab-content .tab-pane.active {
    display: flex;
    flex-wrap: wrap;
  }
  .director-dealings .tab-content .card-documents {
    flex: 40%;
  }
}

@media screen and (max-width: 1150px) {
  .director-dealings .internal-wrap {
    margin-top: 0 !important;
  }
  .director-dealings #tab-directors-dealings.nav-tabs {
    display: flex;
    flex-wrap: wrap;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    margin-bottom: 0 !important;
    margin-top: 0;
  }
  .director-dealings .nav-tabs .nav-item {
    width: 45%;
    margin-bottom: 2rem;
  }
  .director-dealings #tab-directors-dealings.nav-tabs .nav-item button {
    width: 100% !important;
  }
  .director-dealings .tab-content .card-documents {
    height: 14rem;
    display: flex;
  }
  .director-dealings .tab-content .card-documents .pdf-button {
    width: 100% !important;
    float: none;
  }
  .director-dealings .tab-content .card-documents .title {
    padding-bottom: 1rem !important;
  }
}
