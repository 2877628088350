.header a {
  color: #000000;
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  color: #000000;
  font-weight: bold;
  z-index: 100000;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-right: 5rem;
  margin-left: 5rem;
}

.nav__logo {
  color: var(--first-color);
  transition: color 0.4s;
  font-size: var(--h2-font-size);
  font-family: var(--second-font);
}

.nav__toggle,
.nav__close {
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.nav__cta {
  background-color: var(--first-color);
  color: var(--title-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.75rem 1.5rem;
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  list-style-type: none;
}

.nav__link {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.show-menu {
  right: 0;
}

.nav__list__web {
  display: flex;
  flex-direction: row;
  column-gap: 2.5rem;
  align-items: center;
  list-style-type: none;
  padding: 0;
  padding-top: 1.5rem;
}

.nav__link.active {
  color: #6b97cf;
}
@media screen and (max-width: 992px) {
  .nav {
    margin: 1rem;
  }
  .nav__list li a {
    font-size: 25px;
    color: #ffffff;
  }
  .nav__toggle svg {
    font-size: 45px;
    color: #6b97cf;
  }
  .nav__close svg {
    font-size: 45px;
    margin-left: 1rem;
    margin-top: 1.5rem;
  }
  .nav__logo img {
    width: 230px;
    height: auto;
  }
  .nav__list {
    padding-left: 0;
  }
  .accordion-mobile-header {
    --bs-accordion-bg: #305ca7;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 1rem;
  }
  .accordion-mobile-header button {
    border: none;
    background-color: #305ca7;
    color: #fff;
    font-size: 25px;
    font-weight: bold;
    padding: 0;
  }
  .accordion-mobile-header .accordion-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 2rem;
    padding-bottom: 0;
    font-size: 1.3rem;
  }
  .accordion-mobile-header .accordion-body a {
    display: block;
    color: #fff;
    font-weight: normal;
    padding-bottom: 1rem;
  }
  .accordion-mobile-header .accordion-item {
    border: none;
  }
  .accordion-button {
    outline: none !important;
    box-shadow: none !important;
  }
  .accordion-button:not(.collapsed) {
    background-color: #305ca7;
    color: #ffffff;
  }
  .accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
  .accordion-button:focus {
    box-shadow: none;
  }
}
@media screen and (max-width: 1150px) {
  .nav__toggle svg {
    font-size: 45px;
    color: #6b97cf;
  }
  .accordion-mobile-header {
    --bs-accordion-bg: #305ca7;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 1rem;
  }
  .accordion-mobile-header button {
    border: none;
    background-color: #305ca7;
    color: #fff;
    font-size: 25px;
    font-weight: bold;
    padding: 0;
  }
  .accordion-mobile-header .accordion-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 2rem;
    padding-bottom: 0;
    font-size: 1.3rem;
  }
  .accordion-mobile-header .accordion-body a {
    display: block;
    color: #fff;
    font-weight: normal;
    padding-bottom: 1rem;
  }
  .accordion-mobile-header .accordion-item {
    border: none;
  }
  .accordion-button {
    outline: none !important;
    box-shadow: none !important;
  }
  .accordion-button:not(.collapsed) {
    background-color: #305ca7;
    color: #ffffff;
  }
  .accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
  .accordion-button:focus {
    box-shadow: none;
  }
}
#dropdown-services {
  background-color: transparent;
  color: #000000;
  border: none;
  font-size: 16px;
  font-weight: bold;
}
.services-menu {
  width: 250px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  -webkit-border-radius: 3px 3px 0px 0px;
  border-radius: 0px 20px 20px 20px;
  border: none;
  inset: -4px auto auto 12px !important;
}
.services-menu a {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.services-menu a:hover {
  background-color: transparent;
  color: #6b97cf;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
