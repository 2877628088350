#contactUs img {
  height: 4rem;
}
.wrapper-main:has(#contactUs) ~ footer {
  padding-top: 1rem !important;
  z-index: 1000;
}
.font-appuntamento {
  font-size: 0.8rem;
  font-weight: bold;
}
.font-bold {
  font-weight: bold;
}

@media screen and (max-width: 1150px) {
  #contactUs {
    width: 100%;
  }
  #contactUs .container {
    padding-bottom: 4rem !important;
  }
  #contactUs .col-lg-4 {
    padding: 0 1rem 0 1rem !important;
  }
}
