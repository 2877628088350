.wrapper-main:has(.services) ~ footer {
  padding-top: 1rem !important;
  margin-top: -2rem !important;
  z-index: 10;
}
.services .values-container .title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 3px;
}
.services .values-container .font-values {
  font-size: 1rem;
}
.services .values-container img {
  height: 5rem;
  margin-bottom: 10px;
}
.services a.link-document {
  background-color: #6b97cf;
  border: 1px solid #6b97cf;
  color: #ffffff;
  vertical-align: middle;
  text-decoration: none;
  width: 20%;
  padding: 0.8rem;
  font-weight: bold;
  border-radius: 15px;
  font-size: 1.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.services a.link-document:hover {
  border: 1px solid #6b97cf;
  background-color: #ffffff;
  color: #6b97cf;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media screen and (max-width: 1150px) {
  .services {
    padding: 0 1rem 0 1rem;
  }
  .group-detail {
    margin-top: 0rem !important;
  }
  a.link-document {
    width: 70% !important;
  }
  .services .button-wrapper {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
}
